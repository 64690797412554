.home-container {
    padding: 20px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .map-container {
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-bottom: 10px;
  }
  
  .custom-popup .popup-content {
    font-size: 14px;
    line-height: 1.4;
  }
  
  .custom-popup .popup-content strong {
    font-size: 16px;
    color: #333;
  }
  .home-container {
    padding: 20px;
  }
  
  .filter-container {
    margin-bottom: 20px;
  }
  
  .filter-container select {
    padding: 5px;
    font-size: 16px;
  }
  
  .map-container {
    margin-top: 20px;
    height: 600px;
  }
  
  .custom-popup .popup-content {
    font-size: 14px;
    line-height: 1.5;
  }
  