/* BookingDetails.css */

body {
    font-family: 'Roboto', sans-serif;
    background-color: #f0f2f5;
    color: #333;
  }
  
  h4, h5 {
    font-weight: 500;
    color: #212121;
  }
  
  .booking-details-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
  }
  
  .sidebar {
    width: 20%;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .main-content {
    flex: 1;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .paper-container {
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .map-container {
    height: 500px;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }
  
  .form-control, .select-field, .save-button {
    margin-top: 10px;
  }
  
  .save-button {
    background-color: #1a73e8;
    color: #ffffff;
  }
  
  .save-button:hover {
    background-color: #1769aa;
  }
  